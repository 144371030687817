import { produce } from 'immer';
import { isEmpty } from 'utils/common';
import * as types from './taskTypes';
import createReducer from '../../utils/createReducer';

const initialState = {
    records: [],
    tableHeaders: [],
    totalRecords: 0,
    loading: true,
};

const actionHandlers = {
    [types.GET_TASKS.REQUEST]: produce((draft) => {
        draft.loading = true;
    }),
    [types.GET_TASKS.SUCCESS]: produce((draft, { total, records }) => {
        draft.records = records.map((task) => ({
            ...task,
            tasksPointsCount: !isEmpty(task?.tasksPointsLimit)
                ? task?.tasksPointsLimit /
                  (isEmpty(task?.pointsPerSubTask) ? 1 : task?.pointsPerSubTask)
                : '',
        }));
        draft.totalRecords = total;
        draft.loading = false;
    }),
    [types.GET_TASKS.FAILURE]: produce((draft) => {
        draft.loading = false;
    }),
    [types.RESET_TASKS.SUCCESS]: produce((draft) => {
        draft.records = [];
        draft.totalRecords = 0;
        draft.loading = true;
    }),
};

export default createReducer(initialState, actionHandlers);
